var eliAsmaraPhotos = [
  {
    id: 1,
    title: "001",
    alt: "sample alt",
    path: "/images/asmaranyc-01.jpg",
    categoryId: 1,
  },
  {
    id: 2,
    title: "002",
    alt: "sample alt",
    path: "/images/asmaranyc-02.jpg",
    categoryId: 1,
  },
  {
    id: 3,
    title: "003",
    alt: "sample alt",
    path: "/images/asmaranyc-03.jpg",
    categoryId: 1,
  },
  {
    id: 4,
    title: "004",
    alt: "sample alt",
    path: "/images/asmaranyc-04.jpg",
    categoryId: 1,
  },
  {
    id: 5,
    title: "005",
    alt: "sample alt",
    path: "/images/asmaranyc-05.jpg",
    categoryId: 1,
  },
  {
    id: 6,
    title: "006",
    alt: "sample alt",
    path: "/images/asmaranyc-06.jpg",
    categoryId: 1,
  },
  {
    id: 7,
    title: "007",
    alt: "sample alt",
    path: "/images/asmaranyc-07.jpg",
    categoryId: 1,
  },
  {
    id: 8,
    title: "008",
    alt: "sample alt",
    path: "/images/asmaranyc-08.jpg",
    categoryId: 1,
  },
  {
    id: 9,
    title: "009",
    alt: "sample alt",
    path: "/images/asmaranyc-09.jpg",
    categoryId: 1,
  },
  {
    id: 10,
    title: "010",
    alt: "sample alt",
    path: "/images/asmaranyc-10.jpg",
    categoryId: 1,
  },
];

export default eliAsmaraPhotos;
