var paulPhotos = [
  {
    id: 1,
    title: "001",
    alt: "sample alt",
    path: "/images/PAUL_INSTA-01.jpg",
    categoryId: 5,
  },
  {
    id: 2,
    title: "001",
    alt: "sample alt",
    path: "/images/PAUL_INSTA-02.jpg",
    categoryId: 5,
  },
  {
    id: 3,
    title: "001",
    alt: "sample alt",
    path: "/images/PAUL_INSTA-03.jpg",
    categoryId: 5,
  },
  {
    id: 4,
    title: "001",
    alt: "sample alt",
    path: "/images/PAUL_INSTA-04.jpg",
    categoryId: 5,
  },
  {
    id: 5,
    title: "001",
    alt: "sample alt",
    path: "/images/PAUL_INSTA-05.jpg",
    categoryId: 5,
  },
  {
    id: 6,
    title: "001",
    alt: "sample alt",
    path: "/images/PAUL_INSTA-06.jpg",
    categoryId: 5,
  },
  {
    id: 7,
    title: "001",
    alt: "sample alt",
    path: "/images/PAUL_INSTA-07.jpg",
    categoryId: 5,
  },
  {
    id: 8,
    title: "001",
    alt: "sample alt",
    path: "/images/PAUL_INSTA-08.jpg",
    categoryId: 5,
  },
  {
    id: 9,
    title: "001",
    alt: "sample alt",
    path: "/images/PAUL_INSTA-09.jpg",
    categoryId: 5,
  },
  {
    id: 10,
    title: "001",
    alt: "sample alt",
    path: "/images/PAUL_INSTA-10.jpg",
    categoryId: 5,
  },
  {
    id: 11,
    title: "001",
    alt: "sample alt",
    path: "/images/PAUL_INSTA-11.jpg",
    categoryId: 5,
  },
  {
    id: 12,
    title: "001",
    alt: "sample alt",
    path: "/images/PAUL_INSTA-12.jpg",
    categoryId: 5,
  },
  {
    id: 13,
    title: "001",
    alt: "sample alt",
    path: "/images/PAUL_INSTA-13.jpg",
    categoryId: 5,
  },
  {
    id: 14,
    title: "001",
    alt: "sample alt",
    path: "/images/PAUL_INSTA-14.jpg",
    categoryId: 5,
  },
  {
    id: 15,
    title: "001",
    alt: "sample alt",
    path: "/images/PAUL_INSTA-15.jpg",
    categoryId: 5,
  },
  {
    id: 16,
    title: "001",
    alt: "sample alt",
    path: "/images/PAUL_INSTA-16.jpg",
    categoryId: 5,
  },
  {
    id: 17,
    title: "001",
    alt: "sample alt",
    path: "/images/PAUL_INSTA-17.jpg",
    categoryId: 5,
  },
  {
    id: 18,
    title: "018",
    alt: "sample alt",
    path: "/images/PAUL_INSTA-18.jpg",
    categoryId: 5,
  },
];

export default paulPhotos;
