var fridaPhotos = [
  {
    id: 72,
    title: "001",
    alt: "sample alt",
    path: "/images/frida-01.jpg",
    categoryId: 6,
  },
  {
    id: 73,
    title: "001",
    alt: "sample alt",
    path: "/images/frida-02.jpg",
    categoryId: 6,
  },
  {
    id: 74,
    title: "001",
    alt: "sample alt",
    path: "/images/frida-03.jpg",
    categoryId: 6,
  },
  {
    id: 75,
    title: "001",
    alt: "sample alt",
    path: "/images/frida-04.jpg",
    categoryId: 6,
  },
  {
    id: 76,
    title: "001",
    alt: "sample alt",
    path: "/images/frida-05.jpg",
    categoryId: 6,
  },
  {
    id: 77,
    title: "001",
    alt: "sample alt",
    path: "/images/frida-06.jpg",
    categoryId: 6,
  },
  {
    id: 78,
    title: "001",
    alt: "sample alt",
    path: "/images/frida-07.jpg",
    categoryId: 6,
  },
  {
    id: 79,
    title: "001",
    alt: "sample alt",
    path: "/images/frida-08.jpg",
    categoryId: 6,
  },
  {
    id: 80,
    title: "001",
    alt: "sample alt",
    path: "/images/frida-09.jpg",
    categoryId: 6,
  },
  {
    id: 81,
    title: "001",
    alt: "sample alt",
    path: "/images/frida-10.jpg",
    categoryId: 6,
  },
  {
    id: 82,
    title: "001",
    alt: "sample alt",
    path: "/images/frida-11.jpg",
    categoryId: 6,
  },
  {
    id: 82,
    title: "001",
    alt: "sample alt",
    path: "/images/frida-12.jpg",
    categoryId: 6,
  },
  {
    id: 82,
    title: "001",
    alt: "sample alt",
    path: "/images/frida-13.jpg",
    categoryId: 6,
  },
  {
    id: 82,
    title: "001",
    alt: "sample alt",
    path: "/images/frida-14.jpg",
    categoryId: 6,
  },
];

export default fridaPhotos;
