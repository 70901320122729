var niceFrancePhotos = [
  {
    id: 1,
    title: "001",
    alt: "sample alt",
    path: "/images/NiceFrance-01.jpg",
    categoryId: 2,
  },
  {
    id: 2,
    title: "002",
    alt: "sample alt",
    path: "/images/NiceFrance-02.jpg",
    categoryId: 2,
  },
  {
    id: 3,
    title: "003",
    alt: "sample alt",
    path: "/images/NiceFrance-03.jpg",
    categoryId: 2,
  },
  {
    id: 4,
    title: "002",
    alt: "sample alt",
    path: "/images/NiceFrance-04.jpg",
    categoryId: 2,
  },
  {
    id: 5,
    title: "002",
    alt: "sample alt",
    path: "/images/NiceFrance-05.jpg",
    categoryId: 2,
  },
  {
    id: 6,
    title: "002",
    alt: "sample alt",
    path: "/images/NiceFrance-06.jpg",
    categoryId: 2,
  },
  {
    id: 7,
    title: "002",
    alt: "sample alt",
    path: "/images/NiceFrance-07.jpg",
    categoryId: 2,
  },
  {
    id: 8,
    title: "002",
    alt: "sample alt",
    path: "/images/NiceFrance-08.jpg",
    categoryId: 2,
  },
  {
    id: 9,
    title: "002",
    alt: "sample alt",
    path: "/images/NiceFrance-09.jpg",
    categoryId: 2,
  },
  {
    id: 10,
    title: "002",
    alt: "sample alt",
    path: "/images/NiceFrance-10.jpg",
    categoryId: 2,
  },
  {
    id: 11,
    title: "002",
    alt: "sample alt",
    path: "/images/NiceFrance-11.jpg",
    categoryId: 2,
  },
  {
    id: 12,
    title: "002",
    alt: "sample alt",
    path: "/images/NiceFrance-12.jpg",
    categoryId: 2,
  },
  {
    id: 13,
    title: "002",
    alt: "sample alt",
    path: "/images/NiceFrance-13.jpg",
    categoryId: 2,
  },
  {
    id: 14,
    title: "002",
    alt: "sample alt",
    path: "/images/NiceFrance-14.jpg",
    categoryId: 2,
  },
  {
    id: 15,
    title: "002",
    alt: "sample alt",
    path: "/images/NiceFrance-15.jpg",
    categoryId: 2,
  },
  {
    id: 16,
    title: "002",
    alt: "sample alt",
    path: "/images/NiceFrance-16.jpg",
    categoryId: 2,
  },
  {
    id: 17,
    title: "002",
    alt: "sample alt",
    path: "/images/NiceFrance-17.jpg",
    categoryId: 2,
  },
  {
    id: 18,
    title: "002",
    alt: "sample alt",
    path: "/images/NiceFrance-18.jpg",
    categoryId: 2,
  },
  {
    id: 19,
    title: "002",
    alt: "sample alt",
    path: "/images/NiceFrance-19.jpg",
    categoryId: 2,
  },
  {
    id: 20,
    title: "002",
    alt: "sample alt",
    path: "/images/NiceFrance-20.jpg",
    categoryId: 2,
  },
  {
    id: 21,
    title: "002",
    alt: "sample alt",
    path: "/images/NiceFrance-21.jpg",
    categoryId: 2,
  },
  {
    id: 22,
    title: "002",
    alt: "sample alt",
    path: "/images/NiceFrance-22.jpg",
    categoryId: 2,
  },
  {
    id: 23,
    title: "002",
    alt: "sample alt",
    path: "/images/NiceFrance-23.jpg",
    categoryId: 2,
  },
  {
    id: 24,
    title: "002",
    alt: "sample alt",
    path: "/images/NiceFrance-24.jpg",
    categoryId: 2,
  }
];

export default niceFrancePhotos;
