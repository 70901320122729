var paulPhotos = [
  {
    id: 1,
    title: "001",
    alt: "black and white photo of womens face",
    path: "/images/Bojana-1.jpg",
    categoryId: 7,
  },
  {
    id: 2,
    title: "001",
    alt: "black and white photo of womens face",
    path: "/images/Bojana-2.jpg",
    categoryId: 7,
  },
  {
    id: 3,
    title: "001",
    alt: "black and white photo of womens face",
    path: "/images/Bojana-3.jpg",
    categoryId: 7,
  },
  {
    id: 4,
    title: "001",
    alt: "black and white photo of womens face",
    path: "/images/Bojana-4.jpg",
    categoryId: 7,
  },
  {
    id: 5,
    title: "001",
    alt: "black and white photo of womens face",
    path: "/images/Bojana-5.jpg",
    categoryId: 7,
  },
  {
    id: 6,
    title: "001",
    alt: "black and white photo of womens face",
    path: "/images/Bojana-6.jpg",
    categoryId: 7,
  },
  {
    id: 7,
    title: "001",
    alt: "black and white photo of womens face",
    path: "/images/Bojana-7.jpg",
    categoryId: 7,
  },
  {
    id: 8,
    title: "001",
    alt: "black and white photo of womens face",
    path: "/images/Bojana-8.jpg",
    categoryId: 7,
  },
  {
    id: 9,
    title: "001",
    alt: "black and white photo of womens face",
    path: "/images/Bojana-9.jpg",
    categoryId: 7,
  },
  {
    id: 10,
    title: "001",
    alt: "black and white photo of womens face",
    path: "/images/Bojana-10.jpg",
    categoryId: 7,
  },
  {
    id: 11,
    title: "001",
    alt: "black and white photo of womens face",
    path: "/images/Bojana-11.jpg",
    categoryId: 7,
  },
  {
    id: 12,
    title: "001",
    alt: "black and white photo of womens face",
    path: "/images/Bojana-12.jpg",
    categoryId: 7,
  },
  {
    id: 13,
    title: "001",
    alt: "black and white photo of womens face",
    path: "/images/Bojana-13.jpg",
    categoryId: 7,
  },
  {
    id: 14,
    title: "001",
    alt: "black and white photo of womens face",
    path: "/images/Bojana-14.jpg",
    categoryId: 7,
  },
  {
    id: 15,
    title: "001",
    alt: "black and white photo of womens face",
    path: "/images/Bojana-15.jpg",
    categoryId: 7,
  },
  {
    id: 16,
    title: "001",
    alt: "black and white photo of womens face",
    path: "/images/Bojana-16.jpg",
    categoryId: 7,
  },
  {
    id: 17,
    title: "001",
    alt: "black and white photo of womens face",
    path: "/images/Bojana-17.jpg",
    categoryId: 7,
  },
  {
    id: 18,
    title: "018",
    alt: "black and white photo of womens face",
    path: "/images/Bojana-18.jpg",
    categoryId: 7,
  },
  {
    id: 19,
    title: "018",
    alt: "black and white photo of womens face",
    path: "/images/Bojana-19.jpg",
    categoryId: 7,
  },
  {
    id: 20,
    title: "018",
    alt: "black and white photo of womens face",
    path: "/images/Bojana-20.jpg",
    categoryId: 7,
  },
  {
    id: 21,
    title: "018",
    alt: "black and white photo of womens face",
    path: "/images/Bojana-21.jpg",
    categoryId: 7,
  },
];

export default paulPhotos;
