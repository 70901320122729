var duinaPhotos = [
    {
        id: 1,
        title: "002",
        alt: "women in white shirt with red drawings on it",
        path: "/images/duina-01.jpg",
        categoryId: 3,
      },
      {
        id: 2,
        title: "002",
        alt: "women in white shirt with red drawings on it",
        path: "/images/duina-02.jpg",
        categoryId: 3,
      },
      {
        id: 3,
        title: "002",
        alt: "women in white shirt with red drawings on it",
        path: "/images/duina-03.jpg",
        categoryId: 3,
      },
      {
        id: 4,
        title: "002",
        alt: "women in white shirt with red drawings on it",
        path: "/images/duina-04.jpg",
        categoryId: 3,
      },
      {
        id: 5,
        title: "002",
        alt: "women in white shirt with red drawings on it",
        path: "/images/duina-05.jpg",
        categoryId: 3,
      },
      {
        id: 6,
        title: "002",
        alt: "women in white shirt with red drawings on it",
        path: "/images/duina-06.jpg",
        categoryId: 3,
      },
      {
        id: 7,
        title: "002",
        alt: "women in white shirt with red drawings on it",
        path: "/images/duina-07.jpg",
        categoryId: 3,
      },
      {
        id: 8,
        title: "002",
        alt: "women in white shirt with red drawings on it",
        path: "/images/duina-08.jpg",
        categoryId: 3,
      },
      {
        id: 9,
        title: "002",
        alt: "women in white shirt with red drawings on it",
        path: "/images/duina-09.jpg",
        categoryId: 3,
      },
];

export default duinaPhotos;