var dennyLVPhotos = [
    {
        id: 1,
        title: "001",
        alt: "denny skateboarding for Louis Vuitton",
        path: "/images/dennyLV-01.jpg",
        categoryId: 7,
      },
      {
        id: 2,
        title: "001",
        alt: "denny skateboarding for Louis Vuitton",
        path: "/images/dennyLV-02.jpg",
        categoryId: 7,
      },
      {
        id: 3,
        title: "001",
        alt: "denny skateboarding for Louis Vuitton",
        path: "/images/dennyLV-03.jpg",
        categoryId: 7,
      },
      {
        id: 4,
        title: "001",
        alt: "denny skateboarding for Louis Vuitton",
        path: "/images/dennyLV-04.jpg",
        categoryId: 7,
      },
      {
        id: 5,
        title: "001",
        alt: "denny skateboarding for Louis Vuitton",
        path: "/images/dennyLV-05.jpg",
        categoryId: 7,
      },
      {
        id: 6,
        title: "001",
        alt: "denny skateboarding for Louis Vuitton",
        path: "/images/dennyLV-06.jpg",
        categoryId: 7,
      },
      {
        id: 7,
        title: "001",
        alt: "denny skateboarding for Louis Vuitton",
        path: "/images/dennyLV-07.jpg",
        categoryId: 7,
      },
      {
        id: 8,
        title: "001",
        alt: "denny skateboarding for Louis Vuitton",
        path: "/images/dennyLV-08.jpg",
        categoryId: 7,
      },
      {
        id: 9,
        title: "001",
        alt: "denny skateboarding for Louis Vuitton",
        path: "/images/dennyLV-09.jpg",
        categoryId: 7,
      },
      {
        id: 10,
        title: "001",
        alt: "denny skateboarding for Louis Vuitton",
        path: "/images/dennyLV-10.jpg",
        categoryId: 7,
      },
      {
        id: 11,
        title: "001",
        alt: "denny skateboarding for Louis Vuitton",
        path: "/images/dennyLV-11.jpg",
        categoryId: 7,
      },
      {
        id: 12,
        title: "001",
        alt: "denny skateboarding for Louis Vuitton",
        path: "/images/dennyLV-12.jpg",
        categoryId: 7,
      },
      {
        id: 13,
        title: "001",
        alt: "denny skateboarding for Louis Vuitton",
        path: "/images/dennyLV-13.jpg",
        categoryId: 7,
      },
      {
        id: 14,
        title: "001",
        alt: "denny skateboarding for Louis Vuitton",
        path: "/images/dennyLV-14.jpg",
        categoryId: 7,
      },
      {
        id: 15,
        title: "001",
        alt: "denny skateboarding for Louis Vuitton",
        path: "/images/dennyLV-15.jpg",
        categoryId: 7,
      },
      {
        id: 16,
        title: "001",
        alt: "denny skateboarding for Louis Vuitton",
        path: "/images/dennyLV-16.jpg",
        categoryId: 7,
      },
      {
        id: 17,
        title: "001",
        alt: "denny skateboarding for Louis Vuitton",
        path: "/images/dennyLV-17.jpg",
        categoryId: 7,
      },
      {
        id: 18,
        title: "001",
        alt: "denny skateboarding for Louis Vuitton",
        path: "/images/dennyLV-18.jpg",
        categoryId: 7,
      },
      {
        id: 19,
        title: "001",
        alt: "denny skateboarding for Louis Vuitton",
        path: "/images/dennyLV-19.jpg",
        categoryId: 7,
      },
      {
        id: 20,
        title: "001",
        alt: "denny skateboarding for Louis Vuitton",
        path: "/images/dennyLV-20.jpg",
        categoryId: 7,
      },
      {
        id: 21,
        title: "001",
        alt: "denny skateboarding for Louis Vuitton",
        path: "/images/dennyLV-21.jpg",
        categoryId: 7,
      },
]; 

export default dennyLVPhotos;